import useTranslation from 'next-translate/useTranslation';
import { TablePositionCellProps } from './props';
import styles from './styles.module.scss';

export default function TablePositionCell({
  position,
  hideLabel,
}: TablePositionCellProps) {
  const { t } = useTranslation('data-grid');

  return (
    <div className={styles.row}>
      <div
        className={`${styles.row__badge} ${styles[`row__badge--${position}-place`]}`}
      >
        <div
          className={`${styles.row__badge__position} ${styles[`row__badge--${position}-place`]}`}
        >
          {position}
        </div>
      </div>
      {!hideLabel && (
        <p className={styles.row__label}>
          {t('data_grid_place', { place: position })}
        </p>
      )}
    </div>
  );
}
