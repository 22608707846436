import { useCallback, useEffect, useState } from 'react';
import {
  UsePaginatedQueryProps,
  UsePaginatedQueryResponseProps,
} from './props';
import { Nullable } from '../../interfaces';

export function usePaginatedQuery<T>({
  data,
  page,
  setPage,
  queryParams,
}: UsePaginatedQueryProps<T>): UsePaginatedQueryResponseProps {
  const [lastQueryParams, setLastQueryParams] =
    useState<Nullable<object>>(null);
  const setCurrentPage = useCallback(
    (newPage: number) => {
      if (page == newPage) return;
      setPage(newPage);
    },
    [page, setPage],
  );

  const fetchNextPage = useCallback(() => {
    if (!data?.metadata?.hasNextPage) return;

    setPage(old => old + 1);
  }, [data, setPage]);

  const fetchPreviousPage = useCallback(() => {
    if (!data?.metadata?.hasPreviousPage) return;

    setPage(old => old - 1);
  }, [data, setPage]);

  function areObjectValuesDifferent<T>(obj1: T, obj2: T): boolean {
    for (const key of Object.keys(obj1)) {
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }

    return false;
  }

  function onChangeQueryParams() {
    if (!queryParams) {
      return;
    }

    if (!lastQueryParams) {
      setLastQueryParams(queryParams);
      return;
    }

    if (!areObjectValuesDifferent(lastQueryParams, queryParams)) {
      return;
    }

    setLastQueryParams(queryParams);
    setPage(1);
  }

  useEffect(onChangeQueryParams, [queryParams, lastQueryParams, setPage]);

  return {
    setCurrentPage,
    fetchNextPage,
    fetchPreviousPage,
  };
}

export type { UsePaginatedQueryProps, UsePaginatedQueryResponseProps };
